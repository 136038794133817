import React from "react"
import Seo from "../../../components/seo"

import IceGenerators from "./icegenerators"


const IceGeneratorsIndex = () => (

  <>
    <Seo 
      title="Льдогенераторы чешуйчатого, гранулированного и жидкого льда"
      description="Льдогенераторы различных видов льда для хранения различных типов продукции."
      keywords="льдогенератор, жидкий лёд, гранулированный лёд, чешуйчатый лёд."
    />
    <IceGenerators />
  </>
  
)

export default IceGeneratorsIndex